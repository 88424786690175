import React from "react"
import {FaEnvelope, FaFacebook, FaLinkedin, FaTwitter} from 'react-icons/fa';
import {ShareBlockStandard, ShareButtonRectangle} from "react-custom-share";
import ReactMarkdown from "react-markdown";
import rehypeRaw from 'rehype-raw';
import Header from "../components/header"
import Layout from "../components/layout"
import Helmet from "react-helmet"

require("prismjs/themes/prism.css");


export default class BlogDetail extends React.Component {
    componentDidMount() {
        if (typeof (window) !== "undefined") {
            let markdown = document.getElementById('markdown-blog');
            let links = Array.from(markdown.getElementsByTagName('a'));
            links.forEach(link => {
                link.style.color = this.props.data.strapiBlogs.category.color;
                link.target = "_blank";
            });
            let blockquotes = Array.from(markdown.getElementsByTagName('blockquote'));
            blockquotes.forEach(blockquote => {
                blockquote.style.color = this.props.data.strapiBlogs.category.color;
                blockquote.style.borderLeftColor = this.props.data.strapiBlogs.category.color;
            });
            let image = Array.from(markdown.getElementsByTagName('img'));
            image.forEach(images => images.title = images.alt);

            if (this.props.data.strapiBlogs.Block !== null && this.props.data.strapiBlogs.Block.length > 1) {
                let markdownBlock = document.getElementById('markdown-block').innerHTML;
                let block = document.getElementById('block-markdown');
                let newEl = document.createElement('div');
                newEl.className = "block";
                newEl.innerHTML = markdownBlock;
                if(block.parentNode !== null){
                    block.parentNode.replaceChild(newEl, block);
                }

                let titles = Array.from(newEl.getElementsByTagName('h2'));
                titles.forEach(title => {
                    title.style.backgroundColor = this.props.data.strapiBlogs.category.color;
                });
            }
        }
    }

    render() {
        const blogpost = this.props.data.strapiBlogs;
        let siteType = process.env.GATSBY_API_URL;
        const shareBlockProps = {
            url: "https://paixon.ch/blog/" + blogpost.category.url + "/" + blogpost.urlName,
            button: ShareButtonRectangle,
            buttons: [
                {network: "Facebook", icon: FaFacebook},
                {network: "Linkedin", icon: FaLinkedin},
                {network: "Twitter", icon: FaTwitter},
                {network: "Email", icon: FaEnvelope}

            ],
            longtext: blogpost.title
        };

        function formatDate(date) {
            var monthNames = [
                "Januar", "Februar", "März",
                "April", "Mai", "Juni", "Juli",
                "August", "September", "Oktober",
                "November", "Dezember"
            ];

            let updateDate = date.split("-");
            let day = updateDate[2];
            let monthIndex = updateDate[1];
            let year = updateDate[0];

            return day + ". " + monthNames[monthIndex - 1] + " " + year;
        }

        let updateDate = formatDate(blogpost.publishDate);
        let blogData;
        if (siteType === "prod") {
            blogData = this.props.data.allStrapiBlogs.edges.filter(edges => edges.node.status === "published");
        } else if (siteType === "dev") {
            blogData = this.props.data.allStrapiBlogs.edges.filter(edges => edges.node.status === "published" || edges.node.status === "staging");
        }
        let currentBlog = blogData.find(blog => blog.node.title === blogpost.title);
        blogData.splice(blogData.indexOf(currentBlog), 1);
        blogData.sort((a, b) => new Date(b.node.publishDate) - new Date(a.node.publishDate));
        let offersData = this.props.data.allStrapiOfferPages.edges;
        return (
            <Layout page="blog">
                <Helmet>
                    <meta charSet="utf-8"/>
                    <title>{blogpost.meta_title} | Paixon</title>
                    <meta name="description" content={blogpost.meta_description}/>
                    {(siteType === "dev") && (
                        <meta property="image" content={`https://dev.paixon.ch/${blogpost.blog_photo.publicURL}`}/>
                    )}
                    {(siteType === "prod") && (
                        <meta property="image" content={`https://paixon.ch/${blogpost.blog_photo.publicURL}`}/>
                    )}
                    <meta property="image:alt" content={blogpost.meta_photo}/>

                    <script type="application/ld+json">
                        {`{
                            "@context": "https://schema.org",
                            "@type": "NewsArticle",
                            "mainEntityOfPage": {
                                "@type": "WebPage",
                                "@id": "https://paixon.ch/${blogpost.category.url}/${blogpost.urlName}"
                             },
                            "headline": "${blogpost.title}",
                            "image": "https://paixon.ch/${blogpost.blog_photo.publicURL}",
                            "datePublished": "${blogpost.publishDate}",
                            "author": {
                                "@type": "Person",
                                "name": "Paixon"
                            },
                            "publisher": {
                                "@type": "Organization",
                                "name": "Paixon",
                                "logo": {
                                    "@type": "ImageObject",
                                    "url": "https://admin.paixon.ch/uploads/04c2151152b5499dad879b2fb83f1114.png"
                                }
                            }
                            }
                        `}
                    </script>

                </Helmet>
                <div className="blog-header">
                    <Header className="blog" blogphoto={{backgroundImage: `url(${blogpost.header_photo.publicURL})`}}
                            offersData={offersData}/>
                </div>
                <main className="container blog pt details">
                    <div className="blogpost">
                        <h1>{blogpost.title}</h1>
                        <div className="blogdetails">
                            <p className="categoryname"
                               style={{color: `${blogpost.category.color}`}}>{blogpost.category.name}</p>
                            <p className="publishInfos date">Letztes Update am {updateDate}</p>
                            <div className="triangle-up"
                                 style={{borderBottom: `70px solid ${blogpost.category.color}`}}>
                            </div>
                            {this.props.data.allStrapiBlogCategories.edges.filter(edges => edges.node.name === blogpost.category.name)
                                .map(edge => {
                                    return (
                                        <img src={edge.node.blog_category_icon.publicURL}
                                             alt={`${blogpost.category.name} icon`}/>
                                    )
                                })}
                            <div className="blogdetailsline"
                                 style={{backgroundColor: `${blogpost.category.color}`}}></div>
                        </div>
                        <div id="markdown-blog">
                            <ReactMarkdown children={blogpost.content} rehypePlugins={[rehypeRaw]} className={`markdown`}/>
                        </div>

                        <div className='block-hide'>
                            {(blogpost.Block !== null) && (
                                <div id="markdown-block">
                                    <ReactMarkdown children={blogpost.Block} rehypePlugins={[rehypeRaw]}/>
                                </div>
                            )}
                        </div>

                        {blogpost.author && (
                            <div>
                                <hr/>
                                <div className="blogauthor">
                                    <div className="authorportrait">
                                        {this.props.data.allStrapiTeams.edges.filter(nodes => nodes.node.name === blogpost.author.name)
                                            .map(nodes => {
                                                return (
                                                    <a href={`/team/${blogpost.author.url}`}>
                                                        <img src={nodes.node.portraitPhoto.publicURL}
                                                             alt={`${blogpost.author.name} Foto`}/>
                                                    </a>
                                                )
                                            })}
                                    </div>
                                    <div className="authorinfo">
                                        <a href={`/team/${blogpost.author.url}`}>
                                            <h3>{blogpost.author.name}</h3>
                                        </a>
                                        <hr/>

                                        {blogpost.author.blog_description && (
                                            <p className="publishInfos">{blogpost.author.blog_description}</p>
                                        )}
                                        <div className="authorcontact">
                                            <a href={`mailto:${blogpost.author.email}`} title={blogpost.author.email}>
                                                <span className="fa fa-envelope"></span>
                                            </a>
                                            <a
                                                className="linkedin"
                                                href={blogpost.author.linkedin}
                                                title={`Linkedin ${blogpost.author.name}`}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                <span className="fa fa-linkedin"></span>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <hr/>
                            </div>
                        )}
                        <div className="blogshare">
                            <h3>Teile diesen Artikel:</h3>
                            <ShareBlockStandard {...shareBlockProps} />
                        </div>
                    </div>


                </main>

                <div className="container blog pt introBlog">
                    <h3>Weitere Blogbeiträge von Paixon…</h3>
                    <section>
                        <div className="blogFlexcontainer">
                            {blogData.slice(0, 2).map(edge => {
                                const blogpost = edge.node;
                                let updateDate = formatDate(blogpost.publishDate);
                                return (
                                    <div className="element nopadding">
                                        <div className="blogcolumn-element">
                                            <div className="blogcolumn">
                                                <a href={"/blog/" + blogpost.category.url + "/" + blogpost.urlName}>
                                                    <div className="blogline"
                                                         style={{
                                                             borderBottom: `10px solid ${blogpost.category.color}`,
                                                             backgroundImage: `url(${blogpost.blog_photo.publicURL})`
                                                         }}>
                                                        <div className="category-icon">
                                                            <div className="triangle-up"
                                                                 style={{borderBottom: `45px solid ${blogpost.category.color}`}}>
                                                            </div>
                                                            {this.props.data.allStrapiBlogCategories.edges.filter(edges => edges.node.name === blogpost.category.name)
                                                                .map(edge => {
                                                                    return (
                                                                        <img
                                                                            src={edge.node.blog_category_icon.publicURL}
                                                                            alt={`${blogpost.category.name} icon`}/>

                                                                    )
                                                                })}
                                                        </div>
                                                    </div>
                                                </a>
                                            </div>
                                                <div className="bloginfo">
                                                    <p className="publishInfos">{updateDate}</p>
                                                    <a href={"/blog/" + blogpost.category.url + "/" + blogpost.urlName}>
                                                    <h3>{blogpost.title}</h3></a>
                                            </div>
                                            <a className="readbloglink"
                                               href={"/blog/" + blogpost.category.url + "/" + blogpost.urlName}>
                                                <div className='readbloglink-text'>Weiterlesen</div>
                                                </a>
                                            </div>
                                        </div>
                                )
                            })}
                            <div className="element nopadding blogelement">
                                <div className="blogcolumn-element blogelements">
                                    <div className="blogcolumn blogelements-blogpage">
                                        <a href={"/blog"}>
                                            <div className="blogline">
                                            </div>
                                        </a>
                                        </div>
                                    <div className="bloginfo">
                                        <a href={"/blog"}><h3>Weitere Blogbeiträge</h3></a>
                                    </div>
                                    <a className="readbloglink"
                                       href={"/blog"}>
                                        <div className='readbloglink-text'>Weiterlesen</div>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </section>
                </div>
            </Layout>
        )
    }
}

export const query = graphql`
    query BlogDetails($blogUrl: String!)
        {
            strapiBlogs(id
        :
            {
                eq: $blogUrl
            }
        )
            {
                author
                {
                    email
                    linkedin
                    name
                    role
                    blog_description
                    url
                }
                content
                id
                Block
                language
                publishDate
                title
                summary
                urlName
                category
                {
                    id
                    name
                    color
                    url
                }
                blog_photo
                {
                    publicURL
                }
                meta_description
                meta_title
                meta_photo
                header_photo
                {
                    publicURL
                }
            }
            allStrapiBlogCategories
            {
                edges
                {
                    node
                    {
                        name
                        blog_category_icon
                        {
                            publicURL
                        }
                    }
                }
            }
            allStrapiBlogs
            {
                edges
                {
                    node
                    {
                        publishDate
                        title
                        summary
                        urlName
                        status
                        category
                        {
                            name
                            color
                            url
                        }
                        blog_photo
                        {
                            publicURL
                        }
                    }
                }
            }
            allStrapiTeams
            {
                edges
                {
                    node
                    {
                        name
                        portraitPhoto
                        {
                            publicURL
                        }
                    }
                }
            }
            allStrapiOfferPages {
        edges {
            node {
                title
                url
                order
            }
        }
    }
        }
    `;
